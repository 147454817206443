
import {
  IonPage,
  IonHeader,
  IonContent,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import router from "@/router";
import { getusertenderdetail } from "@/service/member";
import Header from "@/components/header.vue";
export default {
  name: "TenderInfo",
  components: { Header, IonHeader, IonContent, IonPage },
  setup() {
    const pagedata: any = ref([]);
    const {
      currentRoute: {
        value: {
          query: { id },
        },
      },
    } = router as any;
    const getusertenderdetailDetail = async () => {
      const result = await getusertenderdetail({
        code: id,
      });
      pagedata.value = result;
    };

    //进入页面并且渲染完页面后触发，每次进入页面都触发
    onIonViewDidEnter(() => {
      console.log("onIonViewDidEnter!");
      getusertenderdetailDetail();
    });
    //进入页面后触发，每次进入页面都触发
    onIonViewWillEnter(() => {
      console.log("onIonViewWillEnter!");
    });

    return { pagedata };
  },
};
